const actions = {
    getCookingTodayReport({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios.post(`/api/admin/subscribe/table_cooking_date`, queryParams)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getQuantititesReport({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios.get(`/api/admin/subscribe/report_quantities`, { params: queryParams })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getDelegateReport({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios.get(`/api/admin/subscribe/report_deliveries`, { params: queryParams })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    exportQuantityCookingDaily({}, date) {

        return new Promise((resolve, reject) => {
            axios.get(`/api/admin/v1/export_quantities_cooking_daily?date=${date}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    downloadCookingTodayPDFReport({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios.get(`/api/admin/subscribe/generate_cooking_today`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },



    export_kitchen_today_export({}, queryParams) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_kitchen_today_export?date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'kitchen_today.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير طبخ لهذا اليوم'); // 422

            })
        })
    },
    export_kitchen_today_export1({}, queryParams) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_kitchen_today_export1?date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'kitchen_today.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير طبخ لهذا اليوم'); // 422

            })
        })
    },
    export_deliveries_export({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_deliveries_export?date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'deliveries.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير مندوب  لهذا اليوم'); // 422

            })
        })
    },
    export_quantities_today({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_quantities_today?date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'quantities_today.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير كميات  لهذا اليوم'); // 422

            })
        })
    },
    export_subscription_data({}) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_subscription_data`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'subscription_data.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير بيانات مشتركين'); // 422

            })
        })
    },

    export_renwal_data_export({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_renwal_data_export?date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'resume_today.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير استئناف  لهذا اليوم'); // 422

            })
        })
    },

    export_customer_registration({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/export_customer_registration?date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'customer_registration.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير استئناف  لهذا اليوم'); // 422

            })
        })
    },

    export_new_subscribes({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/report_new_subscriptions?start_date=${queryParams.date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'new_subscribes.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير اشتراكات جديدة  لهذا اليوم'); // 422

            })
        })

    },


    export_customers_balances({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/report_subscriptions_by_remind_days?remind_days=${queryParams.balance}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'customers_balances.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير أرصدة عملاء'); // 422

            })
        })

    },

    export_compensations({}, queryParams) {

        return new Promise((resolve, reject) => {
            axios({
                url: `/api/admin/report_subscription_compensations?from_date=${queryParams.from_date}&to_date=${queryParams.to_date}`,
                method: 'GET',
                responseType: 'blob',

            }).then((response) => {
                var fileURL = window.URL.createObjectURL(response.data);
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'compensations.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
                resolve(true);
            }).catch(error => {
                if (error.response.status == 500) {
                    reject('حدث خطأ في عملية استخراج البيانات');
                } else reject('لا يوجد تقرير تعويضات للفترة المحددة'); // 422

            })
        })

    },

}
export default {
    namespaced: true,
    actions,
}