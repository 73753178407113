let route = '/api/admin/';
let crud = 'new_menus';
const actions = {

  findData({ }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}/find_v2/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveData({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/store_v2`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateData({ }, data) {
    const id = data.get('id'); // Get the id from the FormData

    return new Promise((resolve, reject) => {
      axios.post(`${route}${crud}/update_v2/${id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },


}
export default {
  namespaced: true,
  actions,
}
