let route = '/api/admin/v1/customers/';
let crud = 'failed_credit_cards';
const actions = {

  getData({ }, queryParams) {
    return new Promise((resolve, reject) => {
      axios.get(`${route}${crud}`, {
        params: queryParams
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  activate({ }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${route}update_failed_credit_cards`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },



}
export default {
  namespaced: true,
  actions,
}
