let route = '/api/admin/';
let crud = 'customers';
const actions = {

    getData({}, queryParams) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}`, {
                    params: queryParams
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    listAll({}) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/list_all`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    findData({}, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    getSubscription({}, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/${id}/get_subscription`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchCookingDailyWithDetails({}, queryParams) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/${queryParams.id}/fetch_cooking_daily_with_details`, {
                    params: queryParams
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchCustomizeCookingDailyDetails({}, queryParams) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/${queryParams.id}/fetch_customize_cooking_daily_details?date=${queryParams.date}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    saveData({}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}`, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateData({}, data) {
        return new Promise((resolve, reject) => {
            let form = new FormData();
            if (data.imageLink) {
                form.append('image', data.imageLink);
                delete data.imageLink;
            }
            if (data.name.ar) form.append('name[ar]', data.name.ar);
            if (data.name.en) form.append('name[en]', data.name.en);
            axios.post(`${route}${crud}/${data.id}`, form)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateStatus({}, id) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/${id}/update_status`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    removeData({}, id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${route}${crud}/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    createCustomer({}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/store`, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    detailsCustomer({}, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/details/${id}`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateDetailsCustomer({}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${route}${crud}/${data.id}/update_details`, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    listValidAddress({}) {
        return new Promise((resolve, reject) => {
            axios.get(`${route}${crud}/list-valid-address`)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    searchCustomers({}, queryParams) {
        return new Promise((resolve, reject) => {

            axios.get(`${route}v1/${crud}/search`, {
                params: queryParams
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error)
            })

        })
    },

    updatedAllowed({ }, data) {
        // If you need to exclude 'id' from the data and send the rest
        let { id, ...otherData } = data;
        return new Promise((resolve, reject) => {
          axios.post(`${route}${crud}/${id}/allow_login`, otherData)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error)
            })
        })
      },


}
export default {
    namespaced: true,
    actions,
}